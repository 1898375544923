import { FC } from "react";
import clsx from "clsx";

import CustomImage from "@/components/MyImageComponent";

interface IGiftCardProps {
  title: string;
  src: string;
  className: string;
}

export const GiftCard: FC<IGiftCardProps> = ({ title, src, className }) => {
  return (
    <div
      className={clsx(
        "flex flex-col relative cursor-pointer group overflow-hidden text-center",
        className
      )}
    >
      <div className="relative w-full aspect-square group-hover:rotate-[20deg] transition-all">
        <CustomImage
          src={src}
          alt={`${title} - MapsMi`}
          layout="fill"
          objectFit="cover"
        />
      </div>

      <h3
        className="mt-[2rem] text-caption z-10 text-text"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h3>
    </div>
  );
};
