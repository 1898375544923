import { useEffect } from "react";

import { useRouter } from "next/router";

// components
import { Layout } from "@/components/Layout/";

// modules
import { Banner } from "@/modules/Home/Banner";

import { OurPosters } from "@/modules/Home/OurPosters";

import { TopSellCategories } from "@/modules/Home/TopSellCategories";
import { TopSellLocations } from "@/modules/Home/TopSellLocations";
import { TopSellSports } from "@/modules/Home/TopSellSports";
import { Gifts } from "@/modules/Home/Gifts";
import { Discount } from "@/modules/Home/Discount";
import { PopularDesigns } from "@/modules/Home/PopularDesigns";

import { JSON_LDS_HOME_PAGE } from "@/helpers/json-lds";
// import { Products } from "@/modules/Home/Products";

// stores

// import { handleShowResetPasswordModal } from "@/stores/modals";

// apis
import { api } from "@/axios";

// helpers
import { toast } from "react-toastify";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

import MetaTags from "@/components/MetaTags";
import Head from "next/head";

export default function Home() {
  const router = useRouter();
  const { t } = useTranslation("meta");

  useEffect(() => {
    if (router.query.confirmToken) {
      api
        .get(`auth/register?confirmToken=${router.query.confirmToken}`)
        .then(({ message }: any) => {
          router.push("/");
          toast.success(message);
        })
        .catch(({ error }) => {
          toast.error(error);
        });
    }

    // if (router.query.resetPasswordToken) {
    //   dispatch(handleShowResetPasswordModal());
    // }
  }, [router.query]);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              JSON_LDS_HOME_PAGE({
                title: t("metaTitle"),
                description: t("metaDescription"),
              })
            ),
          }}
        />
      </Head>

      <MetaTags
        metaTile={t("metaTitle")}
        metaOgTitle={t("metaTitle")}
        metaDescription={t("metaDescription")}
        metaOgDescription={t("metaDescription")}
      />

      <Layout fixed={true} scroll={true}>
        <Banner />
        {/* <Products /> */}

        <div className="sticky top-0">
          <OurPosters />
          <Discount />
          <TopSellCategories />
          <TopSellLocations />
          <TopSellSports />
          <Gifts />
          <PopularDesigns />
        </div>

        {/* <OurBrand /> */}
        {/* <NewProduct /> */}
      </Layout>
    </>
  );
}

export async function getServerSideProps(ctx) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, [
        "home",
        "navigations",
        "modals",
        "meta",
        "categories",
      ])),
    },
  };
}
