// routes
import { popularWallartsRoot, productRoutes } from "@/constants/routers";

import streetmap_1 from "@/public/wallart-preview/streetmap_1.jpg";
import starmap_0 from "@/public/wallart-preview/starmap_0.jpg";
import lineart_0 from "@/public/wallart-preview/lineart_0.jpg";
import zodiac_0 from "@/public/wallart-preview/zodiac_1.jpg";

export const best_categories = [
  { label: "Family", value: "family-posters" },
  { label: "Travel", value: "travel-posters" },
  { label: "Astrology", value: "astrology-posters" },
  { label: "Life Events", value: "life-events-posters" },
  { label: "Sports", value: "sports-posters" },
  { label: "Couples", value: "couples-posters" },
  { label: "Places", value: "places-posters" },
  { label: "History", value: "history-posters" },
  { label: "Landmarks", value: "landmarks-posters" },
  { label: "Nature", value: "nature-posters" },
  { label: "Design Ideas", value: "design-ideas-posters" },
];

export const cities = [
  { label: "Ukraine Posters", value: "ukraine-posters" },
  { label: "Kiev Posters", value: "kiev-posters" },
  { label: "Lviv Posters", value: "lviv-posters" },
  { label: "Amsterdam Posters", value: "amsterdam-posters" },
  { label: "Barcelona Posters", value: "barcelona-posters" },
  { label: "Boston Posters", value: "boston-posters" },
  { label: "Chicago Posters", value: "chicago-posters" },
  { label: "Dubai Posters", value: "dubai-posters" },
  { label: "Las Vegas Posters", value: "las-vegas-posters" },
  { label: "London Posters", value: "london-posters" },
  { label: "New York Posters", value: "new-york-city-posters" },
  { label: "Rome Posters", value: "rome-posters" },
  { label: "Tokyo Posters", value: "tokyo-posters" },
  { label: "Washington DC Posters", value: "washington-dc-posters" },
  { label: "Miami Posters", value: "miami-posters" },
  { label: "Paris Posters", value: "paris-posters" },
];

export const design_type = [
  { label: "Anniversary", value: "anniversary-posters" },
  { label: "Baseball", value: "baseball-posters" },
  { label: "Basketball", value: "basketball-posters" },
  { label: "Birthday", value: "birthday-posters" },
  { label: "Cycling", value: "cycling-posters" },
  { label: "Falling In Love", value: "falling-in-love-posters" },
  { label: "Football", value: "football-posters" },
  { label: "Formula 1", value: "furmula1-posters" },
  { label: "Graduation", value: "graduation-posters" },
  { label: "NASCAR", value: "nascar-posters" },
  { label: "National Park", value: "national-park-posters" },
  { label: "Natural Wonders", value: "natural-wonders-posters" },
  { label: "Newborn", value: "newborn-posters" },
  { label: "Running", value: "running-posters" },
  { label: "Soccer", value: "soccer-posters" },
  { label: "Triathlon", value: "triathlon-posters" },
  { label: "Wedding", value: "wedding-posters" },
  { label: "Zodiac", value: "zodiac-posters" },
  { label: "City", value: "city-posters" },
  { label: "Island", value: "island-posters" },
  { label: "River", value: "river-posters" },
  { label: "Neighborhood", value: "neighborhood-posters" },
  { label: "Archaeological Sities", value: "archaeological-sities-posters" },
  { label: "IndyCar", value: "indy-car-posters" },
  { label: "Motorcycling", value: "motorcycling-posters" },
  { label: "Hiking", value: "hiking-posters" },
  { label: "Mountain", value: "mountain-posters" },
];

export const product_type = [
  { label: "Star Maps", value: "star-maps" },
  { label: "Street Maps", value: "street-maps" },
  { label: "Custom Maps", value: "custom-maps" },
  { label: "Line Art", value: "line-art" },
  { label: "Cat Art", value: "cat-art" },
  { label: "Flowers Art", value: "flowers-art" },
];

export const orientation = [
  { label: "Landscape", value: "landscape" },
  { label: "Portrait", value: "portrait" },
];

export const featured = [
  { label: "On Sale Posters", value: "on-sale" },
  { label: "New Arrivals Posters", value: "new-arrivals" },
  { label: "Staff Picks Posters", value: "staff-picks" },
  { label: "Bundles Posters", value: "bundles" },
];

export const PRODUCTS = [
  {
    href: productRoutes.STREETMAP,
    image: "https://storage.googleapis.com/mapsmi/public/home/streetmap.jpg",
    translate_key: "editor_2_desc",
    translate_key_product_name: "streetmap_name",
  },
  {
    href: productRoutes.LINEART,
    image: "https://storage.googleapis.com/mapsmi/public/home/lineart_0.jpg",
    translate_key: "editor_0_desc",
    translate_key_product_name: "lineart_name",
  },
  {
    href: productRoutes.STARMAP,
    image: "https://storage.googleapis.com/mapsmi/public/home/starmap_0.jpg",
    translate_key: "editor_1_desc",
    translate_key_product_name: "starmap_name",
  },
  {
    href: productRoutes.ZODIAC,
    image: "https://storage.googleapis.com/mapsmi/public/home/zodiac_1.jpg",
    translate_key: "editor_3_desc",
    translate_key_product_name: "zodiac_name",
  },
];

export const FEATUREDS = [
  {
    link: `${popularWallartsRoot}/featured-posters/${featured[0].value}`,
    translate_key: featured[0].value,
  },
  {
    link: `${popularWallartsRoot}/featured-posters/${featured[1].value}`,
    translate_key: featured[1].value,
  },
  {
    link: `${popularWallartsRoot}/featured-posters/${featured[2].value}`,
    translate_key: featured[2].value,
  },
  {
    link: `${popularWallartsRoot}/featured-posters/${featured[3].value}`,
    translate_key: featured[3].value,
  },
];

export const PRODUCT_TYPES = [
  {
    link: `${popularWallartsRoot}/product-type/${product_type[0].value}`,
    translate_key: product_type[0].value,
  },
  {
    link: `${popularWallartsRoot}/product-type/${product_type[1].value}`,
    translate_key: product_type[1].value,
  },
  {
    link: `${popularWallartsRoot}/product-type/${product_type[2].value}`,
    translate_key: product_type[2].value,
  },
  {
    link: `${popularWallartsRoot}/product-type/${product_type[3].value}`,
    translate_key: product_type[3].value,
  },
  {
    link: `${popularWallartsRoot}/product-type/${product_type[4].value}`,
    translate_key: product_type[4].value,
  },
  {
    link: `${popularWallartsRoot}/product-type/${product_type[5].value}`,
    translate_key: product_type[5].value,
  },
];

export const CATEGORIES = [
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[0].value}`,
    translate_key: best_categories[0].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[1].value}/`,
    translate_key: best_categories[1].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[2].value}`,
    translate_key: best_categories[2].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[3].value}`,
    translate_key: best_categories[3].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[4].value}`,
    translate_key: best_categories[4].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[5].value}/`,
    translate_key: best_categories[5].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[6].value}`,
    translate_key: best_categories[6].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[7].value}`,
    translate_key: best_categories[7].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[8].value}`,
    translate_key: best_categories[8].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[9].value}`,
    translate_key: best_categories[9].value,
  },
  {
    link: `${popularWallartsRoot}/best-posters/${best_categories[10].value}`,
    translate_key: best_categories[10].value,
  },
];

export const TOP_SELL_SPORTS = [
  {
    link: `${popularWallartsRoot}/design-posters/${design_type[1].value}`,
    translate_key: design_type[1].value,
  },
  {
    link: `${popularWallartsRoot}/design-posters/${design_type[2].value}`,
    translate_key: design_type[2].value,
  },
  {
    link: `${popularWallartsRoot}/design-posters/${design_type[4].value}`,
    translate_key: design_type[4].value,
  },
  {
    link: `${popularWallartsRoot}/design-posters/${design_type[6].value}`,
    translate_key: design_type[6].value,
  },
  {
    link: `${popularWallartsRoot}/design-posters/${design_type[14].value}`,
    translate_key: design_type[14].value,
  },
  {
    link: `${popularWallartsRoot}/design-posters/${design_type[15].value}`,
    translate_key: design_type[15].value,
  },
];

export const gift = [
  { label: "Gifts for Athletes", value: "gift-for-athletes" },
  { label: "Gifts for Couples", value: "gift-for-couples" },
  { label: "Gifts for Dad", value: "gift-for-dad" },
  { label: "Gifts for Family", value: "gift-for-family" },
  {
    label: "Gifts for Frequent Travelers",
    value: "gift-for-frequent-travelers",
  },
  { label: "Gifts for Friends", value: "gift-for-friends" },
  { label: "Gifts for Grads", value: "gift-for-grads" },
  { label: "Gifts for Grandparents", value: "gift-for-grandparents" },
  { label: "Gifts for Her", value: "gift-for-her" },
  { label: "Gifts for Him", value: "gift-for-him" },
  { label: "Gifts for Husband", value: "gift-for-husband" },
  { label: "Gifts for Kids", value: "gift-for-kids" },
  { label: "Gifts for Mom", value: "gift-for-mom" },
  {
    label: "Gifts for Nature & Outdoor Lovers",
    value: "gift-for-nature-outdoor-lovers",
  },
  { label: "Gifts for Newborns", value: "gift-for-newborns" },
  { label: "Gifts for Newlyweds", value: "gift-for-newlyweds" },
  { label: "Gifts for Sports Fans", value: "gift-for-sports-fans" },
  { label: "Gifts for Students", value: "gift-for-students" },
  { label: "Gifts for Wife", value: "gift-for-wife" },
  { label: "Gifts for Anniversaries", value: "gift-for-anniversaries" },
  { label: "Gifts for Birthdays", value: "gift-for-birthdays" },
];

export const GIFTS = [
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[5].value}`,
    translate_key: gift[5].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[17].value}`,
    translate_key: gift[17].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[1].value}`,
    translate_key: gift[1].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[2].value}`,
    translate_key: gift[2].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[12].value}`,
    translate_key: gift[12].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[3].value}`,
    translate_key: gift[3].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[7].value}`,
    translate_key: gift[7].value,
  },
  {
    link: `${popularWallartsRoot}/gift-posters/${gift[0].value}`,
    translate_key: gift[0].value,
  },
];

export const ORIENTATIONS = [
  {
    link: `${popularWallartsRoot}/orientation/landscape`,
    translate_key: "landscape",
  },
  {
    link: `${popularWallartsRoot}/orientation/portrait`,
    translate_key: "portrait",
  },
];

export const CITIES = [
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[0].value}`,
    translate_key: cities[0].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[1].value}`,
    translate_key: cities[1].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[2].value}`,
    translate_key: cities[2].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[3].value}`,
    translate_key: cities[3].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[4].value}`,
    translate_key: cities[4].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[5].value}`,
    translate_key: cities[5].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[6].value}`,
    translate_key: cities[6].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[7].value}`,
    translate_key: cities[7].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[8].value}`,
    translate_key: cities[8].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[9].value}`,
    translate_key: cities[9].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[10].value}`,
    translate_key: cities[10].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[11].value}`,
    translate_key: cities[11].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[12].value}`,
    translate_key: cities[12].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[13].value}`,
    translate_key: cities[13].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[14].value}`,
    translate_key: cities[14].value,
  },
  {
    link: `${popularWallartsRoot}/cities-posters/${cities[15].value}`,
    translate_key: cities[15].value,
  },
];

export const ALL_OUT_PRODUCTS = [
  {
    link: productRoutes.STARMAP,
    translate_key: "starmap_name",
  },
  {
    link: productRoutes.STREETMAP,
    translate_key: "streetmap_name",
  },
  {
    link: productRoutes.LINEART,
    translate_key: "lineart_name",
  },
  {
    link: productRoutes.ZODIAC,
    translate_key: "zodiac_name",
  },
];

export const DESIGNS = [
  {
    links: FEATUREDS,
    translate_key: "design_featured",
  },
  {
    links: PRODUCT_TYPES,
    translate_key: "design_type",
  },
  {
    links: ORIENTATIONS,
    translate_key: "design_orientation",
  },
  {
    links: CATEGORIES,
    translate_key: "design_categories",
  },
  {
    links: CITIES,
    translate_key: "design_cities",
  },
];

export const ALL_POSTER_TYPES = [
  {
    links: ALL_OUT_PRODUCTS,
    translate_key: "all_our_products",
  },
];
