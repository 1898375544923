import { FC } from "react";
import Link from "next/link";
import clsx from "clsx";
import { Container } from "@/components/Container";
import { Logo } from "@/components/Logo";
import {
  PRODUCTS,
  DESIGNS,
  ALL_POSTER_TYPES,
} from "@/constants/wallart-categories";

import TrustPilotIcon from "@/public/trustpilot.svg";

// routes
import { popularWallartsRoot, publicRoutes } from "@/constants/routers";

interface IFooter {
  className?: string;
}

import { useTranslation } from "next-i18next";

export const Footer: FC<IFooter> = ({ className }) => {
  const { t } = useTranslation(["navigations", "categories"]);

  const OUT_MORE_LINKS = [
    {
      link: publicRoutes.about,
      translate_key: "about",
      title: t(`navigations:about`),
    },
    {
      link: "https://uk.trustpilot.com/",
      target: true,
      translate_key: "reviews",
      title: t(`navigations:reviews`),
    },
    {
      link: publicRoutes.contact,
      translate_key: "contact",
      title: t(`navigations:contact`),
    },
    {
      link: publicRoutes.privacy,
      translate_key: "privacy",
      title: t(`navigations:privacy`),
    },
    {
      link: publicRoutes.terms,
      translate_key: "terms",
      title: t(`navigations:terms`),
    },
  ];

  const OUT_MORE = [
    {
      links: OUT_MORE_LINKS,
      translate_key: "find_out_more",
    },
  ];

  const FOOTER_CAT = [
    DESIGNS[0],
    DESIGNS[3],
    DESIGNS[2],
    ALL_POSTER_TYPES[0],
    OUT_MORE[0],
  ];

  const FOLLOW_ASS = ({ width = 15 }) => {
    return (
      <div className={`w-[${width}%]`}>
        <h5 className="text-bodySmall text-white font-semibold mb-[1rem] capitalize">
          {t("follow_us")}
        </h5>

        <div className="flex flex-col items-start gap-[1rem]">
          <Link
            href={process.env.NEXT_PUBLIC_INSTAGRAM_LINK ?? ""}
            target="_blank"
            className="inline-block"
          >
            <span className="block text-captionSmall text-white hover:underline">
              {t("follow_us_inst")}
            </span>
          </Link>
          <Link href={"/"} className="inline-block">
            <span className="block text-captionSmall text-white hover:underline">
              {t("follow_us_tg")}
            </span>
          </Link>
          <Link href={"/"} className="inline-block">
            <span className="block text-captionSmall text-white hover:underline">
              {t("follow_us_pint")}
            </span>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <footer className={clsx("bg-black", className)}>
      <Container>
        <div className="py-[4rem]">
          <Logo
            className="text-white"
            fill="#fff"
            textColor="text-body font-bold"
            width={30}
          />

          <a
            href="https://www.trustpilot.com/review/mapsmi.com"
            target="_blank"
            rel="noopener"
            className="flex  items-center fill-white mt-[4rem] text-secondary gap-[1rem] text-bodySmall"
          >
            See our reviews on <TrustPilotIcon width={100} />
          </a>

          <div className="hidden lg:block mt-[4rem]">
            <div className="flex gap-[2rem]">
              {FOOTER_CAT.map(({ links, translate_key }, idx) => {
                return (
                  <div key={idx} className="w-[15%]">
                    <h5 className="text-bodySmall text-white font-semibold mb-[1rem] capitalize">
                      {t(`navigations:${translate_key}`)}
                    </h5>

                    {links.length > 0 && (
                      <div className="flex flex-col items-start gap-[1rem]">
                        {links.map(
                          (
                            {
                              title,
                              link,
                              target,
                              translate_key,
                            }: {
                              target: any;
                              title: string;
                              link: string;
                              translate_key: string;
                            },
                            idx
                          ) => {
                            const isTargetBlank = target
                              ? { target: "_blank" }
                              : {};
                            return (
                              <Link
                                href={link}
                                key={idx}
                                className="inline-block"
                                {...isTargetBlank}
                              >
                                <span className="block text-captionSmall text-white hover:underline">
                                  {title
                                    ? title
                                    : t(`categories:${translate_key}`)}{" "}
                                </span>
                              </Link>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
              <FOLLOW_ASS />
            </div>
          </div>

          <div className="flex mt-[4rem] lg:hidden gap-[2rem]">
            {[FOOTER_CAT[4]].map(({ links, translate_key }, idx) => {
              return (
                <div key={idx} className="w-[50%]">
                  <h5 className="text-bodySmall text-white font-semibold mb-[1rem] capitalize">
                    {t(`navigations:${translate_key}`)}
                  </h5>

                  {links.length > 0 && (
                    <div className="flex flex-col items-start gap-[1rem]">
                      {links.map(
                        (
                          {
                            title,
                            link,
                            target,
                            translate_key,
                          }: {
                            target: any;
                            title: string;
                            link: string;
                            translate_key: string;
                          },
                          idx
                        ) => {
                          const isTargetBlank = target
                            ? { target: "_blank" }
                            : {};
                          return (
                            <Link
                              href={link}
                              key={idx}
                              className="inline-block"
                              {...isTargetBlank}
                            >
                              <span className="block text-captionSmall text-white hover:underline">
                                {title
                                  ? title
                                  : t(`categories:${translate_key}`)}
                              </span>
                            </Link>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            <FOLLOW_ASS width={50} />
          </div>
        </div>

        <div className="flex items-center py-[2rem] border-t border-t-[rgba(255,255,255,0.1)]">
          <div className="text-white w-full flex flex-col text-center gap-[0.5rem] sm:flex-row sm:text-left">
            <span className="text-[1.5rem] sm:text-[1.2rem]">
              © {new Date().getFullYear()} MapsMi. All rights reserved.
            </span>
            <div className="flex gap-[1rem] justify-center sm:ml-auto">
              <Link
                href={publicRoutes.privacy}
                className="text-white text-[1.2rem] hover:underline"
              >
                Privacy policy
              </Link>
              <Link
                href={publicRoutes.terms}
                className="text-white text-[1.2rem] hover:underline"
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};
