import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// localstorage
import { setToken, deleteToken } from "@/helpers/storageData";

// constants
import { productsVariations } from "@/constants/constants";

// apis
import { api } from "@/axios";

import axios from "axios";

// helpers
import { toast } from "react-toastify";

import { handleChangeAttributes } from "@/stores/layout";

export const createOrder = async ({
  orderReferenceId,
  customerReferenceId,
  items,
  shipData,
}: {
  orderReferenceId: string;
  customerReferenceId: string;
  items: any;
  shipData: any;
}) => {
  const {
    email,
    userName,
    userLastName,
    country,
    address,
    apartment,
    city,
    phone,
    postcode,
  } = shipData;

  const orderData = {
    orderType: "order",
    orderReferenceId,
    customerReferenceId,
    currency: "USD",
    items,
    shipmentMethodUid: "standard",
    shippingAddress: {
      companyName: "MapsMi",
      firstName: userName,
      lastName: userLastName,
      addressLine1: address,
      addressLine2: apartment,
      state: country,
      city,
      postCode: postcode,
      country,
      email,
      phone,
    },
    returnAddress: {
      companyName: "MapsMi",
      addressLine1: "Krakow, Legnicka 24a, 1, 1",
      addressLine2: "",
      state: "Małopolskie",
      city: "Krakow",
      postCode: "31-312",
      country: "PL",
      email: "mapsminfo@gmail.com",
      phone: "453386293",
    },
    metadata: [],
  };

  // Выполнение асинхронного запроса
  const response = await axios.post(
    "https://order.gelatoapis.com/v4/orders",
    orderData,
    {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": process.env.NEXT_PUBLIC_GELATO_DEV, // Замените на ваш API-ключ
      },
    }
  );

  // Возвращаем данные ответа для последующего использования

  JSON.stringify("RESPONSE", response.data);
  return response.data;
};

const initialState = {
  order: null,
  loading: false,
  error: null,
  variations: {},
};

const gelato = createSlice({
  name: "gelato",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setProductVariations(state, action) {
      state.variations = action.payload;
    },
  },
});

// Асинхронный экшен для загрузки деталей по одному элементу
export const fetchProductVariations = () => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const { frames, papers }: any = await api.get<any>(
      `/product-variations?region=EU`
    );

    // dispatch(handleChangeAttributes("size", papers["600x900-mm"]));
    // dispatch(
    //   handleChangeAttributes({ attr: "size", value: papers["600x900-mm"] })
    // );
    dispatch(setProductVariations({ frames, papers }));
    // Обновляем
    // itemDetails

    return { frames, papers };
  } catch (error) {
    // dispatch(setError(error.message)); // Обрабатываем ошибку
  } finally {
    dispatch(setIsLoading(false));
    // dispatch(setLoading(false));
  }
};

export const { setProductVariations, setIsLoading } = gelato.actions;

export default gelato.reducer;
