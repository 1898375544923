import { FC } from "react";

import { Slider } from "@/components/Slider";
import { Container } from "@/components/Container";
import { SwiperSlide } from "swiper/react";
import { TopSellCategoriesCard } from "@/components/TopSellCategoriesCard";
import { GiftCard } from "@/components/GiftsCard";

import { SliderArrows } from "@/components/Slider/SliderArrows";

import students from "@/public/gifts/students.webp";
import couples from "@/public/gifts/couples.webp";
import dad from "@/public/gifts/dad.webp";
import mam from "@/public/gifts/mam.webp";
import family from "@/public/gifts/family.webp";
import ath from "@/public/gifts/athletes.webp";
import friends from "@/public/gifts/friends.webp";
import grandparents from "@/public/gifts/grandparents.webp";

import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";

import { GIFTS } from "@/constants/wallart-categories";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export const Gifts: FC = (): React.ReactNode => {
  const { t } = useTranslation("home");

  const slides = [
    {
      src: friends.src,
      ...GIFTS[0],
    },
    {
      src: students.src,
      ...GIFTS[1],
    },
    {
      src: couples.src,
      ...GIFTS[2],
    },
    {
      src: dad.src,
      ...GIFTS[3],
    },
    {
      src: mam.src,
      ...GIFTS[4],
    },
    {
      src: family.src,
      ...GIFTS[5],
    },
    {
      src: grandparents.src,
      ...GIFTS[6],
    },
    {
      src: ath.src,
      ...GIFTS[7],
    },
  ];
  return (
    <div className="bg-secondary">
      <Container>
        <div className="py-[2rem] md:py-[5rem]">
          <div className="flex">
            <div>
              <h2 className="text-body md:text-h4 font-semibold mb-[.5rem] text-text">
                {t("gift_title")}
              </h2>
              <p className="text-caption md:text-bodySmall text-text">
                {t("gift_desc")}
              </p>
            </div>
          </div>

          <div className="mt-[1rem] md:mt-[2rem] relative">
            <SliderArrows
              nextArrowClasses="swiper-next-top-sell-gift top-[50%] right-[-10px] lg:right-[-20px] z-10 translate-y-[-50%]"
              prevArrowClasses="swiper-prev-top-sell-gift top-[50%] left-[-10px] lg:left-[-20px] z-10 translate-y-[-50%]"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            />
            <Slider
              loop={false}
              pagination={{ clickable: false }}
              navigation={{
                nextEl: ".swiper-next-top-sell-gift",
                prevEl: ".swiper-prev-top-sell-gift",
              }}
              slidesPerView={5}
              spaceBetween={5}
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                420: {
                  slidesPerView: 4,
                },

                767: {
                  slidesPerView: 5,
                },

                1024: {
                  slidesPerView: 7,
                },

                1620: {
                  slidesPerView: 8,
                  spaceBetween: 15,
                },
              }}
              modules={[Navigation]}
            >
              {slides.map(({ src, link, translate_key }, idx) => {
                const newTitle = t(`categories:${translate_key}`).replace(
                  /^(\S+\s+\S+)\s+/,
                  "$1<br/> "
                );
                return (
                  <SwiperSlide key={idx}>
                    <Link href={link}>
                      <GiftCard title={newTitle} src={src} className="w-full" />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Slider>
          </div>

          {/* <Slider
            className="mt-[2rem]"
            loop={false}
            slidesPerView={4}
            spaceBetween={20}
            pagination={{ clickable: false }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },

              530: {
                slidesPerView: 2,
                spaceBetween: 5,
              },

              980: {
                slidesPerView: 3,
                spaceBetween: 10,
              },

              1620: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
          >
            {slides.map(
              ({ image, imageAlt, id, title, description, disabled }) => {
                return <SwiperSlide key={id}></SwiperSlide>;
              }
            )}
          </Slider> */}
        </div>
      </Container>
    </div>
  );
};
