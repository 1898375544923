import Script from "next/script";
import { GoogleAnalytics, sendGAEvent } from "@next/third-parties/google";

export const GoogleAnalyticsScript = () => {
  return (
    <>
      <GoogleAnalytics
        gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || ""}
        debugMode={false}
      />
    </>
  );
};

// export const triggerGoogleEvent = (eventName?: any) => {
//   console.log("CALL", sendGAEvent);

//   if (typeof window["currDataLayerName"] !== "undefined") {
//     return sendGAEvent("event", "buttonClicked", {
//       value: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_XYZ,
//     });
//   } else {
//     console.warn("Google Analytics is not initialized yet.");
//   }
// };
