import Image from "next/image";
import { useRouter } from "next/router";
import clsx from "clsx";

import { sendGAEvent } from "@next/third-parties/google";

// components
import { Button } from "@/components/Button";
import { ModalContent } from "./ModalContent";

// stores
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/stores/store";
import { useTypedSelector } from "@/stores/store";
import { handleCloseModals } from "@/stores/modals";

import { productNames } from "@/constants/constants";
import { useTranslation } from "react-i18next";

export const ProductVariations = () => {
  const { t } = useTranslation(["home", "modals", "meta", "categories"]);
  const dispatch: AppDispatch = useDispatch();
  const productModal = useTypedSelector(({ modals }) => modals.productModal);
  const router = useRouter();

  const handleGoToEditor = (id: number) => {
    router.push({
      pathname: "/editor",
      query: { product_id: id },
    });
    dispatch(handleCloseModals());
    sendGAEvent("event", "open_editor_page", {
      product_id: productNames[id],
    });
  };

  return (
    <ModalContent isModalOpen={productModal} bgClose bgColor="bg-white">
      {productModal && (
        <div className="flex py-[4rem] px-[2rem] pb-[1.5rem] gap-4 overflow-x-auto scroll-snap-x  md:hide-scrollbar md:mask-right lg:mask-none hide-scrollbar rounded-md hide-scroll">
          <div className="flex flex-col items-center">
            <div className="relative w-[300px] h-[300px] block cards p-[2rem] rounded-lg overflow-hidden">
              <div className="relative w-full h-full">
                <Image
                  alt="Street Map"
                  src="https://storage.googleapis.com/mapsmi/public/home/streetmap.jpg"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              </div>
            </div>
            <h3 className="mt-5 text-[2rem] font-semibold text-black">
              {t(`categories:streetmap_name`)}
            </h3>
            <p className="w-[270px] text-center text-[1.2rem] mt-2 line-clamp-2 text-black">
              {t(`meta:streetmap_desc`)}
            </p>

            <Button
              type="button"
              className="mt-[1rem] flex font-semibold text-[1.2rem] w-full justify-center"
              onClick={() => handleGoToEditor(2)}
              color="secondary"
              rounded
            >
              {t("modal_variations_btn")}
            </Button>
          </div>
          <div className="flex flex-col items-center">
            <div className="relative w-[300px] h-[300px] block cards p-[2rem] rounded-lg overflow-hidden">
              <div className="relative w-full h-full">
                <Image
                  alt={t(`meta:lineart_desc`)}
                  src="https://storage.googleapis.com/mapsmi/public/home/lineart_0.jpg"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              </div>
            </div>
            <h3 className="mt-5 text-[2rem] font-semibold text-black">
              {t(`categories:lineart_name`)}
            </h3>
            <p className="w-[270px] text-center text-[1.2rem] mt-2 line-clamp-2 text-black">
              {t(`meta:lineart_desc`)}
            </p>

            <Button
              type="button"
              className={clsx(
                "mt-[1rem] flex font-semibold text-[1.2rem] w-full justify-center"
              )}
              onClick={() => handleGoToEditor(0)}
              color="secondary"
              rounded
            >
              {t("modal_variations_btn")}
            </Button>
          </div>
          <div className="flex flex-col items-center">
            <div className="relative w-[300px] h-[300px] block cards p-[2rem] rounded-lg overflow-hidden">
              <div className="relative w-full h-full">
                <Image
                  alt={t(`meta:starmap_desc`)}
                  src="https://storage.googleapis.com/mapsmi/public/home/starmap_0.jpg"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              </div>
            </div>
            <h3 className="mt-5 text-[2rem] font-semibold text-black">
              {t(`categories:starmap_name`)}
            </h3>
            <p className="w-[270px] text-center text-[1.2rem] mt-2 line-clamp-2 text-black">
              {t(`meta:starmap_desc`)}
            </p>
            <Button
              type="button"
              className="mt-[1rem] flex font-semibold text-[1.2rem] w-full justify-center"
              onClick={() => handleGoToEditor(1)}
              color="secondary"
              rounded
            >
              {t("modal_variations_btn")}
            </Button>
          </div>

          <div className="flex flex-col items-center">
            <div className="relative w-[300px] h-[300px] block cards p-[2rem] rounded-lg overflow-hidden">
              <div className="relative w-full h-full">
                <Image
                  alt={t(`meta:zodiac_desc`)}
                  src="https://storage.googleapis.com/mapsmi/public/home/zodiac_1.jpg"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              </div>
            </div>
            <h3 className="mt-5 text-[2rem] font-semibold text-black">
              {t(`categories:zodiac_name`)}
            </h3>
            <p className="w-[270px] text-center text-[1.2rem] mt-2 line-clamp-2 text-black">
              {t(`meta:zodiac_desc`)}
            </p>
            <Button
              type="button"
              className="mt-[1rem] flex font-semibold text-[1.2rem] w-full justify-center"
              onClick={() => handleGoToEditor(3)}
              color="secondary"
              rounded
            >
              {t("modal_variations_btn")}
            </Button>
          </div>
        </div>
      )}
    </ModalContent>
  );
};
