export const root = "/";

export const publicRoutes = {
  about: `/about`,
  contact: `/contact`,
  // whyus: `/why-us`,
  privacy: `/privacy`,
  // reviews: `/reviews`,
  terms: `/terms`,
  gifts: "/gifts",
  blog: "/blog",
};

// export const routes = [
//   { route: "/lineart-editor", productId: 0 },
//   { route: "/skymap-editor", productId: 1 },
//   { route: "/map-editor", productId: 2 },
//   { route: "/map-editor", productId: 2 },
// ];

export const popularWallartsRoot = "/posters";

export const productRoutes = {
  LINEART: "/poster/lineart",
  STARMAP: "/poster/starmap",
  STREETMAP: "/poster/streetmap",
  ZODIAC: "/poster/zodiac",
  NATALCHART: "/poster/natal-chart",
};

export const locales = [
  { value: "ua", label: "Ua" },
  { value: "en", label: "Eng" },
  // { value: "pl", label: "Pl", disabled: true },
];
