import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

// dependencies
// import { lineArtIcons } from "@/layouts/wallartSettings/lineArtIcons";
// import { zodiacIcons } from "@/layouts/wallartSettings/zodiacIcons";
import { basicColors } from "@/layouts/wallartSettings/colorsList";
import { basicLayoutStyles } from "@/layouts/wallartSettings/wallartStyles";
import {
  sizes,
  orientations,
  materials,
  // frames,
} from "@/layouts/wallartAttributes";

import { GELATO_PAPER_FORMAT_ID } from "../wallartAttributes";

export const RENDER_SCALE_EDITOR_PAGE = 1;
export const RENDER_SCALE_RENDER_PAGE = 10;

export const MAP_TYPES = {
  ROAD_TYPE: "ROAD_TYPE",
  HILLSHADE_TYPE: "HILLSHADE_TYPE",
};

import { fontsList } from "./wallartFonts";

export const defaultWallartSettings = {
  poster: {
    labels: {
      heading: "Write your heading",
      divider: "",
      subline: "",
      tagline: "",
    },
    styles: {
      artwork: 0,
      color: 0,
      layoutStyle: 0,
      font: fontsList[0].id,
      isMask: false,
      maskId: 0,
      overlayId: 0,
    },
  },
  selectedAttributes: {
    orientation: { ...orientations[0], icon: "" },
    size: {
      price: 0,
      name: "",
    },
    // size: sizes[2],
    material: materials[0],
    frame: { price: 0 },
    // frame: { ...frames[0][0], icon: "" },
    paperGelatoId: GELATO_PAPER_FORMAT_ID,
  },
  productId: 0,
  date: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
  locationsDropdown: [],
  locations: [],
  markers: [],
  uuid: uuidv4(),
  // editingProfileProject: false,
  connectLocations: false,
  renderMarkers: false,
  renderLabels: false,
  mapType: MAP_TYPES["ROAD_TYPE"],
  elementsColor: "#34deeb",
  labelsTextColor: "#000",
  labelsStyle: "fill",
  customCoordinates: {},
  ["best-posters"]: [],
  ["design-posters"]: [],
  ["gift-posters"]: [],
  ["product-type"]: [],
  orientation: [],
  ["featured-posters"]: [],
  ["cities-posters"]: [],
  routeType: 0,
  // price: 0,
  name: "",
};

export const defaultZodiacArtSettings = {
  ...defaultWallartSettings,

  poster: {
    ...defaultWallartSettings.poster,
    styles: {
      ...defaultWallartSettings.poster.styles,
      artwork: 0,
      maskId: 0,
      overlayId: 0,
      isOverlay: false,
      isMask: false,
    },
  },
};

export const defaultSkyMapLayoutSettings = {
  ...defaultWallartSettings,

  poster: {
    ...defaultWallartSettings.poster,
    styles: {
      ...defaultWallartSettings.poster.styles,
      maskId: 0,
      overlayId: 0,
      isOverlay: false,
      isMask: false,
      grid: true,
      lines: true,
      milkyway: true,
      stars: true,
      labels: true,
    },
  },
};
