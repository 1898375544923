import { FC } from "react";
import clsx from "clsx";
// import Image from "next/image";
import CustomImage from "@/components/MyImageComponent";
interface ITopSellCategoriesProps {
  title: string;
  src: string;
  className: string;
}

export const TopSellCategoriesCard: FC<ITopSellCategoriesProps> = ({
  title,
  src,
  className,
}) => {
  return (
    <div
      className={clsx(
        "relative w-full h-[15rem] md:h-[25rem] cursor-pointer group overflow-hidden rounded-2xl bg-primary",
        className
      )}
    >
      <h3 className="absolute top-0 p-[1rem] md:p-[2rem] text-caption z-10 text-text">
        {title}
      </h3>
      <div className="absolute left-[-3%] bottom-[0%] top-[auto] w-[100px] h-[60%] md:w-[200px] md:h-[70%] inset-0 transition-transform duration-300 ease-in-out transform group-hover:scale-125 group-hover:rotate-[-10deg]">
        <CustomImage src={src} alt={title} layout="fill" objectFit="contain" />
      </div>
    </div>
  );
};
