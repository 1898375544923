import Head from "next/head";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

export default function MetaTags({
  metaTile,
  metaDescription,
  metaOgTitle,
  metaOgDescription,
}) {
  const router = useRouter();
  return (
    <>
      <NextSeo
        title={metaTile}
        description={metaDescription}
        canonical={`${process.env.NEXT_PUBLIC_BASE_URL_FRONTEND}${router.asPath}`}
        siteName="MapsMi"
        openGraph={{
          url: `${process.env.NEXT_PUBLIC_BASE_URL_FRONTEND}${router.asPath}`,
          title: metaOgTitle,
          description: metaOgDescription,
          images: [
            {
              url: "https://storage.googleapis.com/mapsmi/public/home/brand.webp",
              width: 800,
              height: 600,
              alt: "MapsMi",
              type: "image/webp",
            },
          ],
          siteName: "MapsMi",
        }}
      />
    </>
  );
}
