import { useRouter } from "next/router";

// components
import { ModalContent } from "./ModalContent";
import { SearchSelect } from "@/components/SearchSelect";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

import { productsVariations } from "@/constants/constants";

// stores
import { useDispatch } from "react-redux";
import { useTypedSelector } from "@/stores/store";
import {
  handleAddToPopularProjects,
  handleDeletePopularProject,
  handleUpdatePopularProject,
} from "@/stores/popular-wallarts";

// import { preRenderScreenShot } from "@/stores/layout";

import { setWallartAdminSettings } from "@/stores/layout";

// import { MATERIAL_PRICES, frames } from "@/layouts/wallartAttributes";
// helpers
import { toast } from "react-toastify";
import { api } from "@/axios";

import {
  best_categories,
  cities,
  design_type,
  gift,
  product_type,
  orientation,
  featured,
} from "@/constants/wallart-categories";
import { useEffect } from "react";

export const ProjectAdminSettings = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { product_id, id, from } = router.query;
  const layout = useTypedSelector(({ layout }) => layout?.layout);
  const prerenderSrc = useTypedSelector(({ layout }) => layout);

  const price =
    Number(layout?.selectedAttributes?.frame?.price) +
    Number(layout?.selectedAttributes?.size?.price);
  // const price = layout?.selectedAttributes?.frame?.type
  //   ? MATERIAL_PRICES[layout?.selectedAttributes?.material?.id]?.prices[
  //       layout.selectedAttributes.size.id
  //     ].price +
  //     frames[layout?.selectedAttributes?.size?.id]?.[
  //       layout?.selectedAttributes?.frame?.id
  //     ]?.price
  //   : MATERIAL_PRICES[layout?.selectedAttributes?.material?.id]?.prices[
  //       layout.selectedAttributes.size.id
  //     ].price;

  const handleAddPupularProject = () => {
    dispatch(handleAddToPopularProjects({ id: product_id, price }));
  };

  const handlePrerenderScreenshot = ({ type = "image" }) => {
    const project = localStorage.getItem(
      productsVariations[Number(product_id)]
    );

    if (project) {
      const createProject = {
        project: { ...JSON.parse(project) },
        type: type,
      };

      const request = api
        .post("/pre-render", createProject)
        .then(({ message, data }: any) => {
          toast.success(message);

          const link = document.createElement("a");
          link.href = data;
          link.download = `preview.${type == "image" ? "webp" : "pdf"}`;
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);

          return data;
        })
        .catch(({ response }) => {
          toast.error(response.data.error);
        });

      toast.promise(request, {
        pending: "Screenshot is generating...",
      });
    }
  };

  const handleUpdateProject = () => {
    dispatch(handleUpdatePopularProject({ id: product_id, price }));
  };

  const handleDeleteFromPupularProject = () => {
    const callback = () => {
      const { from, ...query } = router.query;

      router.push({
        query: query,
      });
    };

    dispatch(
      handleDeletePopularProject({ id: product_id, callback: callback })
    );
  };

  const handleSetWallartAdminSettings = (field, value) => {
    dispatch(setWallartAdminSettings({ field: field, value: value }));
  };

  //

  return (
    <div className="w-full">
      <h3 className="text-h5">Administration</h3>

      <div className="mt-[2rem]">
        <Input
          label="Name"
          value={layout.name}
          onChange={value =>
            handleSetWallartAdminSettings("name", value.target.value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={best_categories}
          mode="multiple"
          allowClear
          className="w-full"
          label="Design Category"
          placeholder="Design Category"
          defaultValue={layout["best-posters"]}
          onChange={value =>
            handleSetWallartAdminSettings("best-posters", value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={design_type}
          mode="multiple"
          allowClear
          className="w-full"
          label="Design Type"
          placeholder="Design Type"
          defaultValue={layout["design-posters"]}
          onChange={value =>
            handleSetWallartAdminSettings("design-posters", value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={gift}
          mode="multiple"
          allowClear
          className="w-full"
          label="Gift"
          placeholder="Gift"
          defaultValue={layout["gift-posters"]}
          onChange={value =>
            handleSetWallartAdminSettings("gift-posters", value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={product_type}
          mode="multiple"
          allowClear
          className="w-full"
          label="Product Type"
          placeholder="Product Type"
          defaultValue={layout["product-type"]}
          onChange={value =>
            handleSetWallartAdminSettings("product-type", value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={orientation}
          mode="multiple"
          allowClear
          className="w-full"
          label="Orientation"
          placeholder="Orientation"
          defaultValue={layout.orientation}
          onChange={value =>
            handleSetWallartAdminSettings("orientation", value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={featured}
          mode="multiple"
          allowClear
          className="w-full"
          label="Featured"
          placeholder="Featured"
          defaultValue={layout["featured-posters"]}
          onChange={value =>
            handleSetWallartAdminSettings("featured-posters", value)
          }
        />
      </div>

      <div className="mt-[2rem]">
        <SearchSelect
          options={cities}
          mode="multiple"
          allowClear
          className="w-full"
          label="Cities"
          placeholder="Cities"
          defaultValue={layout["cities-posters"]}
          onChange={value =>
            handleSetWallartAdminSettings("cities-posters", value)
          }
        />
      </div>

      <div className="mt-[2rem] flex flex-wrap gap-[2rem]">
        <Button
          className="w-full text-caption font-semibold"
          type="button"
          onClick={handleAddPupularProject}
          variant="contained"
          color="primary"
        >
          Create new
        </Button>

        {from == "pupular-wallarts" && (
          <Button
            className="w-full text-caption font-semibold"
            type="button"
            onClick={handleUpdateProject}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        )}

        <Button
          className="w-full text-caption font-semibold"
          type="button"
          onClick={() => handlePrerenderScreenshot({ type: "image" })}
          variant="contained"
          color="secondary"
        >
          PRE RENDER SCREENSHOT
        </Button>

        <Button
          className="w-full text-caption font-semibold"
          type="button"
          onClick={() => handlePrerenderScreenshot({ type: "pdf" })}
          variant="contained"
          color="secondary"
        >
          PRE RENDER PDF
        </Button>

        {from == "pupular-wallarts" && (
          <Button
            className="w-full text-caption font-semibold"
            variant="error"
            type="button"
            onClick={handleDeleteFromPupularProject}
          >
            Delete From Popular
          </Button>
        )}
      </div>
    </div>
  );
};
