import Image from "next/image";
import { useRouter } from "next/router";

// stores
import { useDispatch } from "react-redux";
import { useTypedSelector, AppDispatch } from "@/stores/store";
import { handleDeleteItem } from "@/stores/cart";
import { handleCloseModals } from "@/stores/modals";

import { sendGAEvent } from "@next/third-parties/google";
import CustomImage from "@/components/MyImageComponent";

// components
import { Button } from "@/components/Button";

// constatns
import { productNames } from "@/constants/constants";
// import { MATERIAL_PRICES, frames } from "@/layouts/wallartAttributes";

// icons
import Delete from "@/public/icons/delete.svg";
import Close from "@/public/icons/close.svg";
import { useTranslation } from "react-i18next";

export const Cart = () => {
  const { t } = useTranslation("modals");
  const router = useRouter();
  const dispatch = useDispatch();
  const cartItems = useTypedSelector(({ cart }) => cart?.cart);

  const RESULT_PRICE = cartItems?.reduce((acc, item: any) => {
    const RESULT_PRICE =
      Number(item?.selectedAttributes?.frame?.price) +
      Number(item?.selectedAttributes?.size?.price);

    // const RESULT_PRICE = item?.selectedAttributes?.frame?.type
    //   ? MATERIAL_PRICES[item?.selectedAttributes?.material?.id]?.prices[
    //       item.selectedAttributes.size.id
    //     ].price +
    //     frames[item?.selectedAttributes?.size?.id]?.[
    //       item?.selectedAttributes?.frame?.id
    //     ]?.price
    //   : MATERIAL_PRICES[item?.selectedAttributes?.material?.id]?.prices[
    //       item.selectedAttributes.size.id
    //     ].price;

    const itemPrice = RESULT_PRICE;
    const itemQuantity = item.quantity || 1;

    return acc + itemPrice * itemQuantity;
  }, 0);
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center mb-[2rem] pb-[1rem] sticky top-0 bg-white left-0 right-0 w-full">
        <h3 className="text-body  border-secondaryBg leading-[1]">
          {t("basket_title")}
        </h3>

        <button
          type="button"
          className="ml-auto"
          onClick={() => {
            dispatch(handleCloseModals());
          }}
        >
          <Close width={30} height={30} fill="#111" />
        </button>
      </div>

      <div className="flex flex-col">
        {!cartItems.length ? (
          <div className="text-center mt-[12rem]">
            <h3
              className="text-bodyBold"
              dangerouslySetInnerHTML={{ __html: t("basket_empty_title") }}
            ></h3>
            <p
              className="text-bodySmall mt-[1rem]"
              dangerouslySetInnerHTML={{ __html: t("basket_empty_sub_title") }}
            ></p>
          </div>
        ) : null}

        {cartItems.map((item: any, index: number) => {
          const { selectedAttributes } = item;

          const RESULT_PRICE =
            Number(item?.selectedAttributes?.frame?.price) +
            Number(item?.selectedAttributes?.size?.price);
          // const RESULT_PRICE = item?.selectedAttributes?.frame?.type
          //   ? MATERIAL_PRICES[item?.selectedAttributes?.material?.id]?.prices[
          //       item.selectedAttributes.size.id
          //     ].price +
          //     frames[item?.selectedAttributes?.size?.id]?.[
          //       item?.selectedAttributes?.frame?.id
          //     ]?.price
          //   : MATERIAL_PRICES[item?.selectedAttributes?.material?.id]?.prices[
          //       item.selectedAttributes.size.id
          //     ].price;

          return (
            <div key={index} className="mb-[2rem]">
              <div className="flex items-start gap-[2rem]">
                <div className="flex items-center w-full relative">
                  <div className="relative">
                    <CustomImage
                      src={`${item.path}?v=${new Date().getMilliseconds()}`}
                      alt={`${productNames[item.productId]} - MapsMi`}
                      width={200}
                      height={200}
                      quality={100}
                      className="object-cover"
                    />
                  </div>
                  <div className="ml-[2rem] w-full">
                    <h3 className="text-body mb-[.5rem] truncate">
                      {productNames[item.productId]}
                    </h3>
                    <h4 className="text-bodySmall mb-[.4rem]">
                      {selectedAttributes.orientation.name} -{" "}
                      {selectedAttributes.size.name}
                    </h4>

                    <p className="text-captionSmall font-bold">
                      {t("basket_price")}: {item.quantity} x{" "}
                      {Number(RESULT_PRICE).toFixed(2)} $
                    </p>
                    <p className="text-captionSmall font-bold mt-[.4rem]">
                      {t("basket_item_quantity")}: {item.quantity}
                    </p>
                    <button
                      onClick={() =>
                        dispatch(
                          handleDeleteItem({
                            layout: item,
                            promiseMessage: t("tost_project_deleting"),
                            successMessage: t("tost_project_deleting_success"),
                          })
                        )
                      }
                      className="mr-auto mt-[1rem]"
                    >
                      <Delete width={24} fill="red" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {cartItems.length ? (
        <>
          <div className="sticky mt-auto bottom-0 w-full right-0 bg-white pt-[1rem]">
            <div className="mt-auto text-bodySmall lg:text-body mb-[2rem] font-black">
              {t("basket_price")}: {Number(RESULT_PRICE).toFixed(2)} $
            </div>
            <Button
              variant="contained"
              color="primary"
              className="mt-auto text-bodySmall text-center w-full"
              onClick={() => {
                sendGAEvent("event", "oper_cart_page", {
                  price: RESULT_PRICE,
                  wallartItemsCount: cartItems.length,
                });

                router.push("/cart");
                dispatch(handleCloseModals());
              }}
            >
              {t("basket_checkout_btn")}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
