export const JSON_LDS_HOME_PAGE = ({ title, description }) => {
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://www.mapsmi.com/#organization",
        name: title,
        url: "https://www.mapsmi.com/",
        sameAs: ["https://www.instagram.com/mapsmingle/"],
        logo: {
          "@type": "ImageObject",
          inLanguage: "en-GB",
          "@id": "https://storage.googleapis.com/mapsmi/public/source/logo.svg",
          url: "https://storage.googleapis.com/mapsmi/public/source/logo.svg",
          contentUrl:
            "https://storage.googleapis.com/mapsmi/public/source/logo.svg",
          width: 100,
          height: 100,
          caption: "MapsMi",
        },
        image: {
          "@id": "https://storage.googleapis.com/mapsmi/public/source/logo.svg",
        },
      },
      {
        "@type": "WebSite",
        "@id": "https://www.mapsmi.com/#website",
        url: "https://www.mapsmi.com/",
        name: "MapsMi",
        description: description,
        publisher: {
          "@id": "https://www.mapsmi.com/#organization",
        },
        potentialAction: [
          {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate:
                "https://www.mapsmi.com/search?q={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: "en-GB",
      },
      {
        "@type": "ImageObject",
        inLanguage: "en-GB",
        "@id": "https://storage.googleapis.com/mapsmi/public/home/brand.webp",
        url: "https://storage.googleapis.com/mapsmi/public/home/brand.webp",
        contentUrl:
          "https://storage.googleapis.com/mapsmi/public/home/brand.webp",
        width: 800,
        height: 600,
        caption: description,
      },
      {
        "@type": "WebPage",
        "@id": "https://www.mapsmi.com/",
        url: "https://www.mapsmi.com/",
        name: title,
        isPartOf: {
          "@id": "https://www.mapsmi.com/#website",
        },
        about: {
          "@id": "https://www.mapsmi.com/#organization",
        },
        primaryImageOfPage: {
          "@id": "https://www.mapsmi.com/#primaryimage",
        },
        image: {
          "@id": "https://www.mapsmi.com/#primaryimage",
        },
        thumbnailUrl:
          "https://storage.googleapis.com/mapsmi/public/home/brand.webp",
        datePublished: "2022-01-01T09:00:00+00:00",
        dateModified: "2025-01-18T12:00:00+00:00",
        description: description,
        breadcrumb: {
          "@id": "https://www.mapsmi.com/#breadcrumb",
        },
        inLanguage: "en-GB",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://www.mapsmi.com/"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.mapsmi.com/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.mapsmi.com/",
          },
        ],
      },
    ],
  };
};
