// import Image from "next/image";
import clsx from "clsx";
import CustomImage from "@/components/MyImageComponent";

// components
import Link from "next/link";

import Arrow from "@/public/icons/line-arrow.svg";

interface Props {
  image?: any;
  imageAlt?: any;
  title?: String;
  description?: React.ReactElement;
  disabled?: boolean;
  className?: String;
  href?: string;
  id?: string | number;
}

export const PreviewWallartCard = ({
  image,
  imageAlt,
  title,
  description,
  disabled,
  className,
  href = "/",
  id,
  ...props
}: Props) => {
  return (
    <Link href={href}>
      <div className={clsx("group relative flex flex-col", className)}>
        <div className="w-full relative aspect-square bg-secondary cards py-[2rem] px-[2rem] group/item">
          {disabled && (
            <div className="absolute bg-black/[0.5] z-20 w-full h-full top-0 flex items-center justify-center">
              <h3 className="text-body text-primary">Cooming Soon</h3>
            </div>
          )}

          <div className="w-full h-full relative group-hover:blur-[2px] transition-all">
            <CustomImage
              src={image}
              alt={imageAlt}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
              quality={100}
            />
          </div>

          <div className="absolute h-[8px] bottom-0 left-0 right-0 w-[0%] bg-secondaryBg group-hover:w-[100%] transition-all text-center"></div>
        </div>

        <div className="py-[2rem] pb-[1rem] px-[1rem] flex flex-col">
          <h3 className="flex items-center font-semibold text-bodySmall md:text-h6 text-text">
            {title} <Arrow width={15} className="ml-auto" fill="#283c8a" />
          </h3>

          {description}

          <span className="block mt-[1.5rem] font-semibold text-bodySmall text-text">
            $ 44.99 - 79.99
          </span>
          <span className="block font-semibold text-captionSmall text-text">
            UAH {(44.99 * 45).toFixed(2)} - {(79.99 * 45).toFixed(2)}
          </span>
        </div>
      </div>
    </Link>
  );
};
