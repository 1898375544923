import Image from "next/image";
import { useState } from "react";
import { Loader } from "@/components/Loader";

export default function CustomImage({ src, alt, ...props }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      {isLoading && (
        <div className="loader-wrapper">
          <Loader width={20} />
        </div>
      )}
      <Image
        src={src}
        alt={alt}
        {...props}
        onLoadingComplete={() => setIsLoading(false)}
      />
      <style jsx>{`
        .loader-wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
      `}</style>
    </div>
  );
}
