import { FC } from "react";
import Link from "next/link";
import { Slider } from "@/components/Slider";
import { Container } from "@/components/Container";
import { SwiperSlide } from "swiper/react";
import { TopSellCategoriesCard } from "@/components/TopSellCategoriesCard";

import Kiev from "@/public/top-selling-categories/kiev.png";
import NewYork from "@/public/top-selling-categories/new-york.png";
import LasVegas from "@/public/top-selling-categories/las-vegas.png";
import Miami from "@/public/top-selling-categories/miami.png";
import Paris from "@/public/top-selling-categories/paris.png";
import Amsterdam from "@/public/top-selling-categories/amsterdam.png";
import Barcelona from "@/public/top-selling-categories/barcelona.png";
import Boston from "@/public/top-selling-categories/boston.png";
import Chikago from "@/public/top-selling-categories/chikago.png";
import Dubai from "@/public/top-selling-categories/dubai.png";
import Ukraine from "@/public/top-selling-categories/ukraine.png";
import Rome from "@/public/top-selling-categories/rome.png";
import Tokio from "@/public/top-selling-categories/tokyo.png";
import Ws from "@/public/top-selling-categories/ws.png";
import London from "@/public/top-selling-categories/london.png";
import Lviv from "@/public/top-selling-categories/lviv.png";

import { SliderArrows } from "@/components/Slider/SliderArrows";

import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";

import { CITIES } from "@/constants/wallart-categories";

import { useTranslation } from "next-i18next";

export const TopSellLocations: FC = (): React.ReactNode => {
  const { t } = useTranslation("home");

  const slides = [
    {
      src: Ukraine.src,
      ...CITIES[0],
    },
    {
      src: Kiev.src,
      ...CITIES[1],
    },
    {
      src: Lviv.src,
      ...CITIES[2],
    },
    {
      src: Amsterdam.src,
      ...CITIES[3],
    },
    {
      src: Barcelona.src,
      ...CITIES[4],
    },
    {
      src: Boston.src,
      ...CITIES[5],
    },
    {
      src: Chikago.src,
      ...CITIES[6],
    },
    {
      src: Dubai.src,
      ...CITIES[7],
    },
    {
      src: LasVegas.src,
      ...CITIES[8],
    },
    {
      src: London.src,
      ...CITIES[9],
    },
    {
      src: NewYork.src,
      ...CITIES[10],
    },
    {
      src: Rome.src,
      ...CITIES[11],
    },
    {
      src: Tokio.src,
      ...CITIES[12],
    },
    {
      src: Ws.src,
      ...CITIES[13],
    },

    {
      src: Miami.src,
      ...CITIES[14],
    },
    {
      src: Paris.src,
      ...CITIES[15],
    },
  ];
  return (
    <div className="bg-secondary">
      <Container>
        <div className="py-[2rem] md:py-[5rem]">
          <div className="flex">
            <div>
              <h2 className="text-body md:text-h4 font-semibold mb-[.5rem] text-text">
                {t("trending_title")}
              </h2>
              <p className="text-caption md:text-bodySmall text-text">
                {t("trending_desc")}
              </p>
            </div>
          </div>

          <div className="mt-[1rem] md:mt-[2rem] relative">
            <SliderArrows
              nextArrowClasses="swiper-next-top-sell-loc top-[50%] right-[-10px] lg:right-[-20px] z-10 translate-y-[-50%]"
              prevArrowClasses="swiper-prev-top-sell-loc top-[50%] left-[-10px] lg:left-[-20px] z-10 translate-y-[-50%]"
              wrapperClasses="gap-[1rem]"
              wrapper={false}
            />
            <Slider
              loop={false}
              pagination={{ clickable: false }}
              navigation={{
                nextEl: ".swiper-next-top-sell-loc",
                prevEl: ".swiper-prev-top-sell-loc",
              }}
              slidesPerView={5}
              spaceBetween={5}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                },

                530: {
                  slidesPerView: 3,
                },

                980: {
                  slidesPerView: 4,
                },

                1620: {
                  slidesPerView: 5,
                },
              }}
              modules={[Navigation]}
            >
              {slides.map(({ src, title, link, translate_key }: any, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <Link href={link}>
                      <TopSellCategoriesCard
                        title={t(`categories:${translate_key}`)}
                        src={src}
                        className="w-full bg-white"
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Slider>
          </div>

          {/* <Slider
            className="mt-[2rem]"
            loop={false}
            slidesPerView={4}
            spaceBetween={20}
            pagination={{ clickable: false }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },

              530: {
                slidesPerView: 2,
                spaceBetween: 5,
              },

              980: {
                slidesPerView: 3,
                spaceBetween: 10,
              },

              1620: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
          >
            {slides.map(
              ({ image, imageAlt, id, title, description, disabled }) => {
                return <SwiperSlide key={id}></SwiperSlide>;
              }
            )}
          </Slider> */}
        </div>
      </Container>
    </div>
  );
};
