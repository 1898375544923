import { useEffect, useState } from "react";
import { useRouter } from "next/router";
// import Image from "next/image";
import CustomImage from "@/components/MyImageComponent";
// stores
import { useDispatch } from "react-redux";
import { storagePoster } from "@/helpers/storageData";

import { getLayout } from "@/stores/layout";
import { handleShowGlobalLoader, handleCloseModals } from "@/stores/modals";

export const CartProduct = ({ name, price, path, productId, ...props }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <div
      className="flex flex-col m-[0.5rem] md:m-[1rem] cursor-pointer"
      onClick={() => {
        dispatch(handleShowGlobalLoader());
        localStorage.removeItem("map-storage");
        storagePoster({
          productId: productId,
          layout: {
            ...{ ...props, productId, path, name },
          },
        });
        dispatch(getLayout({ productId: productId }));
        router.push({
          pathname: "/editor",
          query: {
            product_id: productId,
            from: "pupular-wallarts",
            // fields: JSON.stringify(props),
          },
        });
        // dispatch(handleCloseModals());
      }}
    >
      <div className="w-full pt-[120%] relative bg-secondary">
        <div className="absolute top-0 left-0 h-full w-full">
          <div className="w-full h-full p-[10%] group">
            <div className="w-full h-full relative scale-[0.9] group-hover:scale-[1] transition-all">
              <CustomImage
                src={path}
                alt={name}
                objectFit="contain"
                quality={100}
                layout="fill"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[2rem] px-[0.4rem] md:px-[2rem] w-full">
        <h2 className="capitalize text-center mx-auto text-caption w-[100%] truncate">
          {name}
        </h2>

        {price && (
          <h2 className="text-center mx-auto text-captionSmall mt-[0.5rem] md:mt-[1rem] text-nowrap whitespace-nowrap">
            As Designed:{" "}
            <span className="block font-bold text-text">
              $ {Number(price).toFixed(2)}
            </span>
            <span className="block font-bold text-text text-captionXs">
              UAH {Number(price * 45).toFixed(2)}
            </span>
          </h2>
        )}
      </div>
    </div>
  );
};
