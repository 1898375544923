import Image from "next/image";
import frame1 from "@/public/frames/icons/frame1.png";
import frame2 from "@/public/frames/icons/frame2.png";
import frame3 from "@/public/frames/icons/frame3.png";
import frame4 from "@/public/frames/icons/frame4.png";
import frame5 from "@/public/frames/icons/frame5.png";
import frame6 from "@/public/frames/icons/frame6.png";
import frame7 from "@/public/frames/icons/frame6.png";
import empty from "@/public/frames/icons/empty.png";
import Empty from "@/public/frames/icons/empty.svg";

// orientations
import Landscape from "@/public/orientations/landscape.svg";
import Portrain from "@/public/orientations/portrait.svg";

export interface SizeInterface {
  name: string;
  id: number;
}

export const replaceDemenssionss = size => {
  const sizeDemenssions = size.split(/\D+/).filter(Boolean).map(Number);

  return {
    width: Math.round((sizeDemenssions[0] * 300) / 2.54),
    height: Math.round((sizeDemenssions[1] * 300) / 2.54),
  };
};

export const ROUTE_TYPES = {
  0: {
    name: "airplane",
    id: 0,
    // pic: "https://storage.mixplaces.com/mixplace-files/s3fs-public/fields/mediaiconfieldmediaicon/icon-route-airplane.png",
  },
  1: {
    name: "driving",
    id: 1,
    // pic: "https://storage.mixplaces.com/mixplace-files/s3fs-public/fields/mediaiconfieldmediaicon/icon-route-driving.png",
  },
  2: {
    name: "walking",
    id: 2,
    // pic: "https://storage.mixplaces.com/mixplace-files/s3fs-public/fields/mediaiconfieldmediaicon/icon-route-walking.png",
  },
};

export const GELATO_PAPER_FORMAT_ID = "200-gsm-80lb-uncoated_4-0";

export const sizes = [
  {
    name: "30x40cm",
    id: 0,
    ...replaceDemenssionss("30x40cm"),
    inch: "12x16-inch",
    mm: "300x400-mm",
  },
  {
    name: "50x70cm",
    id: 1,
    ...replaceDemenssionss("50x70cm"),
    inch: "20x28-inch",
    mm: "500x700-mm",
  },
  {
    name: "60x90cm",
    id: 2,
    ...replaceDemenssionss("60x90cm"),
    inch: "24x36-inch",
    mm: "600x900-mm",
  },
  // {
  //   name: "70x100cm",
  //   id: 2,
  //   ...replaceDemenssionss("70x100cm"),
  //   inch: "28x40-inch",
  //   mm: "700x1000-mm",
  // },
];

console.log("sizes", sizes);

const SMALL_MATERIAL_PRICE = 33.74;
const SMALL_MATERIAL_OLD_PRICE = 44.99;
//
const MEDIUM_MATERIAL_PRICE = 44.99;
const MEDIUM_MATERIAL_OLD_PRICE = 59.99;
//
const BIG_MATERIAL_PRICE = 59.99;
const BIG_MATERIAL_OLD_PRICE = 79.99;

export const MATERIAL_PRICES = [
  {
    id: 0,
    prices: [
      { id: 0, price: SMALL_MATERIAL_PRICE },
      { id: 1, price: MEDIUM_MATERIAL_PRICE },
      { id: 2, price: BIG_MATERIAL_PRICE },
      //
      // { id: 3, price: 1800 },
    ],
  },
  {
    id: 1,
    prices: [
      { id: 0, price: SMALL_MATERIAL_PRICE },
      { id: 1, price: MEDIUM_MATERIAL_PRICE },
      { id: 2, price: BIG_MATERIAL_PRICE },
      // { id: 1, price: 1599 },
      // { id: 2, price: 1899 },
      //
      // { id: 3, price: 2400 },
    ],
  },
  {
    id: 2,
    prices: [
      { id: 0, price: SMALL_MATERIAL_PRICE },
      { id: 1, price: MEDIUM_MATERIAL_PRICE },
      { id: 2, price: BIG_MATERIAL_PRICE },
      // { id: 1, price: 1800 },
      // { id: 2, price: 3200 },
    ],
  },
];

export const materials = [
  {
    name: "Paper",
    id: 0,
    disabled: false,
    sizes: [
      { ...sizes[0], id: 0 },
      { ...sizes[1], id: 1 },
      { ...sizes[2], id: 2 },
    ],
  },
  {
    name: "Canvas (Cooming Soon)",
    id: 1,
    disabled: true,
    sizes: [
      { ...sizes[0], id: 0 },
      { ...sizes[1], id: 1 },
      { ...sizes[2], id: 2 },
    ],
  },

  // {
  //   name: "Plastik",
  //   id: 1,
  //   sizes: [
  //     { ...sizes[0], id: 0 },
  //     { ...sizes[1], id: 1 },
  //     { ...sizes[2], id: 2 },
  //   ],
  // },
  // {
  //   name: "Holst",
  //   id: 1,
  //   sizes: [
  //     { ...sizes[0], id: 0 },
  //     { ...sizes[1], id: 1 },
  //     { ...sizes[2], id: 2 },
  //     //
  //     // { ...sizes[3], id: 3 },
  //   ],
  // },
];

export interface OrientationsInterface {
  name: string;
  id: number;
  icon?: any;
  orientationGelatoId: string;
}

export const orientations: OrientationsInterface[] = [
  { name: "Portrait", id: 0, icon: <Portrain />, orientationGelatoId: "ver" },
  {
    name: "Landscape",
    id: 1,
    icon: <Landscape />,
    orientationGelatoId: "hor",
  },
];

export const FRAMES_TYPES = {
  0: "small",
  1: "medium",
  2: "big",
};

const emptyFrame = {
  id: 0,
  name: "",
  color: "",
  material: "",
  price: 0,
  icon: <Empty width={20} height={20} />,
  frameGelatoTag: "",
};

const SMALL_FRAME_PRICE = 26.99;
const SMALL_FRAME_OLD_PRICE = 29.99;
//
const MEDIUM_FRAME_PRICE = 35.99;
const MEDIUM_FRAME_OLD_PRICE = 39.99;
//
const BIG_FRAME_PRICE = 53.99;
const BIG_FRAME_OLD_PRICE = 59.99;

const GELATO_GLASSES_UID = "w12xt22-mm_plexiglass";
const GELATO_ORIENTATIONS_UID = ["_ver", "_hor"];

const createFrame = (
  id: number,
  color: string,
  material: string,
  price: number,
  oldPrice: number,
  type: string,
  icon: JSX.Element,
  gelatoMaterialColor: string,
  frameGelatoTag: string = "framed_poster_mounted",
  glassGelatoTag: string = GELATO_GLASSES_UID
) => ({
  id,
  name: "type-frame",
  color,
  material,
  price,
  oldPrice,
  type,
  icon,
  gelatoMaterialColor,
  frameGelatoTag,
  glassGelatoTag,
});

export const GELATO_WOOD_NAURAL = "natural-wood_wood";
export const GELATO_WOOD_WHITE = "white_wood";
export const GELATO_WOOD_BLACK = "black_wood";

const GELATO_FRAME_MATERIALS = [
  GELATO_WOOD_NAURAL,
  GELATO_WOOD_WHITE,
  GELATO_WOOD_BLACK,
];

const PAPER_UIDS = sizes.flatMap(size =>
  GELATO_ORIENTATIONS_UID.map(
    orientation =>
      `flat_${size.mm}-${size.inch}_${GELATO_PAPER_FORMAT_ID}${orientation}`
  )
);

const FREAMED_UIDS = sizes.flatMap(size =>
  GELATO_FRAME_MATERIALS.flatMap(material =>
    GELATO_ORIENTATIONS_UID.map(
      orientation =>
        `framed_poster_mounted_${size.mm}-${size.inch}_${material}_${GELATO_GLASSES_UID}_${size.mm}-${size.inch}_${GELATO_PAPER_FORMAT_ID}${orientation}`
    )
  )
);

export const frameIcons = {
  ["none"]: <Empty width={20} height={20} />,
  [GELATO_WOOD_NAURAL]: (
    <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />
  ),
  [GELATO_WOOD_BLACK]: (
    <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />
  ),
  [GELATO_WOOD_WHITE]: (
    <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />
  ),
};

const frameData = {
  small: [
    {
      id: 1,
      color: "color-natural",
      material: "material-wood",
      icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_NAURAL,
    },
    {
      id: 2,
      color: "color-white",
      material: "material-wood",
      icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_WHITE,
    },
    {
      id: 3,
      color: "color-black",
      material: "material-wood",
      icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_BLACK,
    },
  ],
  medium: [
    {
      id: 1,
      color: "color-natural",
      material: "material-wood",
      icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_NAURAL,
    },
    {
      id: 2,
      color: "color-white",
      material: "material-wood",
      icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_WHITE,
    },
    {
      id: 3,
      color: "color-black",
      material: "material-wood",
      icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_BLACK,
    },
  ],
  big: [
    {
      id: 1,
      color: "color-natural",
      material: "material-wood",
      icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_NAURAL,
    },
    {
      id: 2,
      color: "color-white",
      material: "material-wood",
      icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_WHITE,
    },
    {
      id: 3,
      color: "color-black",
      material: "material-wood",
      icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
      gelatoMaterialColor: GELATO_WOOD_BLACK,
    },
  ],
};

// export const frames = {
//   0: [
//     emptyFrame,
//     ...frameData.small.map(frame =>
//       createFrame(
//         frame.id,
//         frame.color,
//         frame.material,
//         SMALL_FRAME_PRICE,
//         29.99,
//         FRAMES_TYPES[0],
//         frame.icon,
//         frame.gelatoMaterialColor
//       )
//     ),
//   ],
//   1: [
//     emptyFrame,
//     ...frameData.medium.map(frame =>
//       createFrame(
//         frame.id,
//         frame.color,
//         frame.material,
//         MEDIUM_FRAME_PRICE,
//         39.99,
//         FRAMES_TYPES[1],
//         frame.icon,
//         frame.gelatoMaterialColor
//       )
//     ),
//   ],
//   2: [
//     emptyFrame,
//     ...frameData.big.map(frame =>
//       createFrame(
//         frame.id,
//         frame.color,
//         frame.material,
//         BIG_FRAME_PRICE,
//         59.99,
//         FRAMES_TYPES[2],
//         frame.icon,
//         frame.gelatoMaterialColor
//       )
//     ),
//   ],
// };

// export const frames = {
//   0: [
//     emptyFrame,
//     {
//       id: 1,
//       name: "type-frame",
//       color: "color-natural",
//       material: "material-wood",
//       price: SMALL_FRAME_PRICE,
//       oldPrice: SMALL_FRAME_OLD_PRICE,
//       type: FRAMES_TYPES[0],
//       icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
//       gelatoTag: "framed_poster_mounted",
//     },
//     {
//       id: 2,
//       name: "type-frame",
//       color: "color-white",
//       material: "material-wood",
//       price: SMALL_FRAME_PRICE,
//       oldPrice: SMALL_FRAME_OLD_PRICE,
//       type: FRAMES_TYPES[0],
//       icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
//     },
//     {
//       id: 3,
//       name: "type-frame",
//       color: "color-black",
//       material: "material-wood",
//       type: FRAMES_TYPES[0],
//       price: SMALL_FRAME_PRICE,
//       oldPrice: SMALL_FRAME_OLD_PRICE,
//       icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
//     },
//   ],
//   1: [
//     emptyFrame,
//     {
//       id: 1,
//       name: "type-frame",
//       color: "color-natural",
//       material: "material-wood",
//       price: MEDIUM_FRAME_PRICE,
//       oldPrice: MEDIUM_FRAME_OLD_PRICE,
//       type: FRAMES_TYPES[1],
//       icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
//     },
//     {
//       id: 2,
//       name: "type-frame",
//       color: "color-white",
//       material: "material-wood",
//       price: MEDIUM_FRAME_PRICE,
//       oldPrice: MEDIUM_FRAME_OLD_PRICE,

//       type: FRAMES_TYPES[1],
//       icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
//     },
//     {
//       id: 3,
//       name: "type-frame",
//       color: "color-black",
//       material: "material-wood",
//       price: MEDIUM_FRAME_PRICE,
//       oldPrice: MEDIUM_FRAME_OLD_PRICE,

//       type: FRAMES_TYPES[1],
//       icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
//     },
//   ],
//   2: [
//     emptyFrame,
//     {
//       id: 1,
//       name: "type-frame",
//       color: "color-natural",
//       material: "material-wood",
//       price: BIG_FRAME_PRICE,
//       oldPrice: BIG_FRAME_OLD_PRICE,

//       type: FRAMES_TYPES[2],
//       icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
//     },
//     {
//       id: 2,
//       name: "type-frame",
//       color: "color-white",
//       material: "material-wood",
//       price: BIG_FRAME_PRICE,
//       oldPrice: BIG_FRAME_OLD_PRICE,

//       type: FRAMES_TYPES[2],
//       icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
//     },
//     {
//       id: 3,
//       name: "type-frame",
//       color: "color-black",
//       material: "material-wood",
//       price: BIG_FRAME_PRICE,
//       oldPrice: BIG_FRAME_OLD_PRICE,

//       type: FRAMES_TYPES[2],
//       icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
//     },

//     // {
//     //   id: 4,
//     //   name: "type-hanger",
//     //   color: "color-natural",
//     //   material: "material-wood",
//     //   price: 0,
//     //   oldPrice: 0,
//     //   type: FRAMES_TYPES[2],
//     //   icon: <Image src={frame1} layout="fill" alt="frame" objectFit="cover" />,
//     // },
//     // {
//     //   id: 5,
//     //   name: "type-hanger",
//     //   color: "color-white",
//     //   material: "material-wood",
//     //   icon: <Image src={frame2} layout="fill" alt="frame" objectFit="cover" />,
//     // },
//     // {
//     //   id: 6,
//     //   name: "type-hanger",
//     //   color: "color-black",
//     //   material: "material-wood",
//     //   icon: <Image src={frame3} layout="fill" alt="frame" objectFit="cover" />,
//     // },

//     ,
//   ],
//   // 3: [
//   //   emptyFrame,
//   //   {
//   //     id: 1,
//   //     name: "type-frame",
//   //     color: "color-natural",
//   //     material: "material-wood",
//   //     price: 2198,
//   //     oldPrice: 2299,

//   //     type: FRAMES_TYPES[3],
//   //     icon: <Image src={frame4} layout="fill" alt="frame" objectFit="cover" />,
//   //   },
//   //   {
//   //     id: 2,
//   //     name: "type-frame",
//   //     color: "color-white",
//   //     material: "material-wood",
//   //     price: 2198,
//   //     oldPrice: 2299,

//   //     type: FRAMES_TYPES[3],
//   //     icon: <Image src={frame5} layout="fill" alt="frame" objectFit="cover" />,
//   //   },
//   //   {
//   //     id: 3,
//   //     name: "type-frame",
//   //     color: "color-black",
//   //     material: "material-wood",
//   //     price: 2198,
//   //     oldPrice: 2299,

//   //     type: FRAMES_TYPES[3],
//   //     icon: <Image src={frame6} layout="fill" alt="frame" objectFit="cover" />,
//   //   },
//   // ],
// };
